/*Cambia el color del background - Esto pasara cuando usen un Card-Header por defecto se pone la clase bg-default
El cual se pondra el color de la variable ya asignada para llevar un estilo*/
.bg-default{
    background-color: var(--color_font_title) !important;
}

/*This code is for form of register tramites, we can change the color of span in date fields*/
.form-check-label > span {
    color: var(--color_font_text);
}

/*Cuando usamos el Wizzard con formio para generar pasos en los formularios, los botones que estan en el header se les coloca
el z-indez en 3 y eso causa ruido en nuestro navbar, por lo tanto se coloco el indez 0 para que no estorbara*/
.page-item .page-link {
    z-index: 0;
}

.page-item.active .page-link {
    z-index: 0;
}

/*La data que este dentro de los dropdown de formio se colocaran de color negro, ya que el color por default de formio es blanco 
y nuestro template es de color blanco*/
.choices__list > .choices__item > span{
    color: var(--color_font_text);
}

/*Esta clase es para arreglar el problema en la alineacion del input y del boton que vengan desde formio, esta clase se debe
colocar dentro del boton al crear el boton de formio*/
.flex-button-formio {
    display: flex;
    align-items: flex-end;
}

/*Los mensajes de error que manda formio se los pondremos de color rojo*/
.formio-errors .error {
    color: var(--color_font-error) !important;
}

.help-block {
    display: none;
}


/*Boton del numero oficial, se modifica su posicion directamente al formulario de formio*/
.numero-oficial > div > div > div > div > div {
    display: flex;
    flex-direction: column-reverse;   
}

/*Login Formio Component*/
#login-formio > div > div > div > div > div  > .formio-component-submit {
    text-align: right;
}

/*Formio numerooficialciudadano*/
#numero-oficial > div > div > div > div {
    display: flex;
    align-items: flex-end;
}

#document-form > div > div > div > div > table > tbody > tr > td > div > div > div > ul > li > div > .col-md-9 > strong {
    visibility: hidden;
}
#document-form > div > div > div > div > table > tbody > tr > td > div > div > div > ul > li > div > .col-md-9> strong::after {
    content: "Nombre de archivo";
    visibility: visible;
    float:left
}
#document-form > div > div > div > div > table > tbody > tr > td > div > div > div > ul > li > div > .col-md-2 > strong {
    visibility: hidden;
}
/*#document-form > div > div > div > div > table > tbody > tr > td > div > div > div > ul > li > div > .col-md-2> strong::after {
    content: "Tamaño";
    visibility: visible;
    float:left
}
*/
@media only screen and (max-width: 767px) {
    
    #login-formio > div > div > div > div > div  > .formio-component-submit {
        text-align: left !important;
    }
}