/*
    * Archivo principal de las propiedades de los componentes

    - En este apartado podremos encontrar las propiedades especificas de las clases y propiedades de los
    componentes
*/

/* Estas clases cambian todos los colores y acciones de las clases btn-primary, usar 
esta clase en los botones
    *Buttons
*/
.btn-primary-reverse {
    border-color: var(--color_main_page) !important;
    background-color: var(--color_font_background) !important;
    color: var(--color_font_button) !important;
    font-weight: bold !important;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:visited {
    border-color: var(--color_font_background) !important;
    background-color: var(--color_font_button) !important;
    color: var(--color_font_text) !important;
    font-weight: bold !important;
}

.btn-primary-active {
    border-color: var(--color_font_background);
    background-color: var(--color_font_background) !important;
    color: var(--color_font_button) !important;
}


.btn-primary:hover {
    border-color: var(--color_font_background);
    background-color: var(--color_font_background) !important;
    color: var(--color_font_button) !important;
}

#delete-arrow > div > .dropdown-toggle::after {
    display: none;
}
/*------------------------------------------------------------------------------------*/

/*Las Etiquetas con la propiedad de btn-link tendran el color asignado por la variable
    *Link Button
*/
.btn-link {
    color: var(--color_font_text);
}

.btn-link:hover {
    color: var(--color_font_title);
}
/*-----------------------------------------------------------------------------------*/

/*Los botones tipo outline tendran la propiedad siguiente
    * Outline button
*/

.btn-outline-secondary {
    color: var(--color_font_button);
}

/*-----------------------------------------------------------------------------------*/
/*Los estilos de scrollbar, Track, Thum*/

/**----------------------------------------------------------------------------------*/

/*Input Search de boostrap, se modifico su index por medio de una especificacion, cada vez que se use ese tipo de input
 usar este id*/
#searchBox.input {
    z-index: 0;
}
/*-------------------------------------------------------------------------------------*/

/* Estas clases son exclusivamente para la barra de busqueda*/
.searchbar {
    margin-bottom: auto;
    margin-top: auto;
    height: 60px;
    background-color: white;
    border-radius: 30px;
    padding: 10px;
}

.search_input {
    color: black;
    border: 0;
    outline: 0;
    background: none;
    width: 0;
    caret-color: transparent;
    line-height: 40px;
    transition: width 0.4s linear;
}

.searchbar:hover > .search_input {
    padding: 0 10px;
    width: 350px;
    caret-color: red;
    transition: width 0.4s linear;
}

.searchbar:hover > .search_icon {
    background: white;
    color: #e74c3c;
}

.search_icon {
    height: 40px;
    width: 40px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    text-decoration: none;
}
/*----------------------------------------------------------------------------*/
/*Los componentes nav-tabs de boostrap tienen por default usar un color gris cuando esta seleccionado, pero con esta propiedad
tendremos todos los nav-tabs con el color principal*/
.nav-tabs .nav-link.active {
    color: var(--color_font_background);
}
.nav-pills .nav-link.active {
    background-color: var(--color_font_background);
}
/*----------------------------------------------------------------------------*/
/*La propiedad de este acordio nos sirve para que el dropdown de nuestra mainpage no se sobre ponga*/
.accordion > .card {
    overflow: inherit;
}
/*Propiedades para el tamaño de la caja del video*/
.video {
    display: block;
    width: 100%;
    height: 350px;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 1000px;
}

/*La etiqueta de lista se modifica con la especificidad con el id de numbers-out*/
#numbers-out {
    list-style: none;
}
/*Mapbox componente */
.mapContainer {
    width: 100%;
    height: 800px;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 1500px;
}

canvas, .mapboxgl-canvas {
    height: 100% !important;
    width: 100% !important;
}

/*Table of boostrap properties force changed*/
.row.react-bootstrap-table-pagination {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.react-bootstrap-table-pagination-list.col-md-6.col-xs-6.col-sm-6.col-lg-6 {
    justify-content: center;
    display: flex;
}
/*-------------------------------------------*/

#content-pdf {
    margin-left: 80px;
    width: 650px;
    height: auto;
    font-size: 10px;
    padding-left: 10px;
}
@media only screen and (max-width: 768px) {
    .searchbar:hover > .search_input {
        padding: 0 8px;
        width: 190px;
        caret-color: var(--color_font_text);
        transition: width 0.4s linear;
    }
}
