/*
    * Archivo principal de fuentes
    - En este apartado podremos encontrar la fuente que se importo desde la carpeta public/font/Seravek
*/



/*Las etiquetas h2 de la pagina tendran las siguiente propiedades*/
h1 {
    color: white;
    font-size: 3rem;
}

/*Las etiquetas h2 de la pagina tendran las siguiente propiedades*/
h2 {
    color: var(--color_font_title);
    font-weight: var(--font_weight);
}

.color_font_footer {
        color: var(--color_font_footer) !important;
}

/* etiquetas para los titulos */
.font-seravek-medium{
        font-family: "Seravek-medium";
}

.poppins-regular{
        font-family: "Poppins-Regular";
}

.poppins-bold{
        font-family: "Poppins-Bold";
}

@font-face {
        font-family: "Seravek-medium";
        src: local("Seravek-medium"), url("../fonts/Seravek/Seravek-Medium.ttf") format("truetype");
}

@font-face {
        font-family: "Seravek";
        src: local("Seravek"), url("../fonts/Seravek/Seravek.ttf") format("truetype");
}

@font-face {
        font-family: "Poppins-Regular";
        src: local("Poppins-Regular"), url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
        font-family: "Poppins-Bold";
        src: local("Poppins-Bold"), url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}