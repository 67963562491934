/*
    * Archivo principal de colores

    - En este apartado podremos encontrar los colores con ciertas variables globales las cuales debemos usar en nuestra
    pagina.
*/

:root {
    --color_font_title: #d4c19c;;
    --color_font_background: #7E191B;
    --color_font_text: #2c2c2c;
    --color_font_footer: #bb9265e3;
    --color_font_footer_title: #d3bd9e;
    --color_font_button: #f5f5f5;
    --color_font-error: #ff0000;
    --color_font_secundary: #343a40;
    
    /*Color of tags of table*/
    --color_pendiente: #686868;
    --color_cancelado: #dc3545;
    --color_revision: #51b755;
    --color_entregado: #2451b3;
    --color_impreso: #17a2b8;
    --color_ventanilla: #ff851b;
    --color_solicitud: #3d9970;
    --color_pendiente_ciudadano: #50034ada;
    --color_entregado_negativa: #ccc904;
    --color_pendiente_pago: #0400ffb9;
}